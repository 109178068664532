import React from 'react'

import HeadSEO from '@atoms/HeadSEO'
import { TemplateProvider } from '@hooks/useTemplate'
import OrdersHomeTemplate from '@templates/Orders/Home'
import Consts from '@utils/constants'
import { usePageContext } from '@HOCs/Providers/PageContext'
import { IPageProps } from '@ts-types/Page'
import { Faq } from '@ts-types/Faq'
import { LanguageTranslations, Translations } from '@ts-types/Translations'
import { PAGES_ID } from '@config/pages/const'
import { getUrlByPageId } from '@utils/getUrlByPageId'
import { resolvePagePath } from '@utils/resolvePagePath'

interface Props extends IPageProps {}

const {
  ORDERS_HOME_TRANSLATION,
  CREATE_MANUAL_STORE_ORDERS_TRANSLATIONS,
  CREATE_MANUAL_USER_ORDERS_TRANSLATIONS,
  MARK_ORDERS_AS_COMPLETED_TRANSLATIONS,
  CANCEL_ORDERS_TRANSLATIONS,
  ORDERS_REFUNDS_TRANSLATIONS,
  ORDERS_PAYMENTS_TRANSLATIONS,
  ORDERS_DOCS_TRANSLATIONS,
} = Consts.translates

const _ORDER_MANAGEMENT_LIST = [
  CREATE_MANUAL_STORE_ORDERS_TRANSLATIONS,
  CREATE_MANUAL_USER_ORDERS_TRANSLATIONS,
  MARK_ORDERS_AS_COMPLETED_TRANSLATIONS,
  CANCEL_ORDERS_TRANSLATIONS,
  ORDERS_REFUNDS_TRANSLATIONS,
]

const _PAYMENT_AND_DOCS_LIST = [
  ORDERS_PAYMENTS_TRANSLATIONS,
  ORDERS_DOCS_TRANSLATIONS,
]

export default function OrdersHomeContainer() {
  const { lang } = usePageContext()

  const topics = [
    {
      title: ORDERS_HOME_TRANSLATION[lang].MANAGING_ORDERS,
      items: _ORDER_MANAGEMENT_LIST.map((t: Translations) => ({
        label: t[lang].ARTICLE?.['TITLE'],
        url: resolvePagePath(t[lang].PAGE_ID, lang)?.pathname || '',
      })),
    },
    {
      title: ORDERS_HOME_TRANSLATION[lang].PAYMENTS_AND_DOCS,
      items: _PAYMENT_AND_DOCS_LIST.map((t: Translations) => ({
        label: t[lang].ARTICLE?.['TITLE'],
        url: resolvePagePath(t[lang].PAGE_ID, lang)?.pathname || '',
      })),
    },
  ]

  const faqs: Faq[] = (
    ORDERS_HOME_TRANSLATION[lang].FAQ as LanguageTranslations[]
  ).map(({ QUESTION, ANSWER }: LanguageTranslations) => ({
    question: QUESTION as string,
    anwser: ANSWER as string,
  }))

  return (
    <TemplateProvider
      value={{ translate: ORDERS_HOME_TRANSLATION, lang, faqs, topics }}
    >
      <OrdersHomeTemplate />
    </TemplateProvider>
  )
}

export function Head({ pageContext }: Props) {
  const { lang } = pageContext || {}

  const { organization, logo } = Consts.schemeOrg

  const faqList = (
    ORDERS_HOME_TRANSLATION[lang].FAQ as LanguageTranslations[]
  ).map(({ QUESTION, ANSWER }: LanguageTranslations) => ({
    '@type': 'Question',
    name: QUESTION,
    headline: QUESTION,
    answerCount: 1,
    datePublished: '2025-03-23T00:00:00Z',
    dateModified: '2025-03-23T00:00:00Z',
    author: organization.organizationField,
    acceptedAnswer: {
      '@type': 'Answer',
      text: ANSWER,
    },
  }))

  const orderManagementList = _ORDER_MANAGEMENT_LIST.map(
    (t: Translations, index: number) => ({
      '@type': 'Article',
      position: index + 1,
      image: logo.logoField,
      name: t[lang].SEO_TITLE,
      headline: t[lang].SEO_TITLE,
      url: getUrlByPageId(t[lang].PAGE_ID, lang) || '',
      author: organization.organizationField,
      description: t[lang].SEO_DESCRIPTION,
      keywords: t[lang].SEO_KEYWORDS,
    })
  )

  const paymentAndDocsList = _PAYMENT_AND_DOCS_LIST.map(
    (t: Translations, index: number) => ({
      '@type': 'Article',
      position: index + 1,
      image: logo.logoField,
      name: t[lang].SEO_TITLE,
      headline: t[lang].SEO_TITLE,
      url: getUrlByPageId(t[lang].PAGE_ID, lang) || '',
      author: organization.organizationField,
      description: t[lang].SEO_DESCRIPTION,
      keywords: t[lang].SEO_KEYWORDS,
    })
  )

  return (
    <HeadSEO
      title={ORDERS_HOME_TRANSLATION[lang].TITLE as string}
      description={ORDERS_HOME_TRANSLATION[lang].DESCRIPTION as string}
      keywords={ORDERS_HOME_TRANSLATION[lang].SEO_KEYWORDS as string}
      lang={lang}
      pageId={PAGES_ID.ORDERS_HOME}
      jsonLd={[
        {
          '@type': 'WebPage',
          '@id': getUrlByPageId(PAGES_ID.ORDERS_HOME, lang),
          name: ORDERS_HOME_TRANSLATION[lang].TITLE,
          description: ORDERS_HOME_TRANSLATION[lang].DESCRIPTION,
          url: getUrlByPageId(PAGES_ID.ORDERS_HOME, lang),
          breadcrumb: {
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: ORDERS_HOME_TRANSLATION[lang].BREADCRUMBS['HOME'],
                item: getUrlByPageId(PAGES_ID.HOME, lang),
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: ORDERS_HOME_TRANSLATION[lang].BREADCRUMBS['ORDERS_HOME'],
                item: getUrlByPageId(PAGES_ID.ORDERS_HOME, lang),
              },
            ],
          },
          mainEntity: {
            '@type': 'FAQPage',
            headline: ORDERS_HOME_TRANSLATION[lang].TITLE,
            reviewedBy: organization.organizationField,
            mainEntity: faqList,
            author: organization.organizationField,
            publisher: organization.organizationField,
            datePublished: '2025-03-23T00:00:00Z',
            dateModified: '2025-03-23T00:00:00Z',
            keywords: ORDERS_HOME_TRANSLATION[lang].SEO_KEYWORDS as string,
          },
        },
        // {
        //   '@type': 'ItemList',
        //   itemListOrder: 'https://schema.org/ItemListOrderUnordered',
        //   name: ORDERS_HOME_TRANSLATION[lang].MANAGING_ORDERS,
        //   itemListElement: orderManagementList,
        // },
        // {
        //   '@type': 'ItemList',
        //   itemListOrder: 'https://schema.org/ItemListOrderUnordered',
        //   name: ORDERS_HOME_TRANSLATION[lang].PAYMENTS_AND_DOCS,
        //   itemListElement: paymentAndDocsList,
        // },
      ]}
    />
  )
}
